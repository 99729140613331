<template>
    <div class="dropdown">
        <FormulateInput
            type="select"
            v-model="value"
            :options="dropdownList"
            :placeholder="sysLabelGetter('area')"
            @change="action(value)"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        dropdownList: {
            type: Object,
            default: () => {},
        },
        action: {
            type: Function,
            default: () => {},
        },
        selected: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            value: "",
        };
    },
    computed: {
        ...mapGetters("main", {
            sysLabelGetter:'sysLabelGetter',
        }),
    },
    mounted() {
        this.value = this.selected;
    },
    watch: {
        selected() {
            this.value = this.selected;
        },
    },
};
</script>

<style lang="scss" scoped></style>