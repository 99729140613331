<template>
    <BaseCard class="languages">
        <b-button
            variant="success"
            class="language"
            v-for="(language, name) in languages"
            :key="name"
            @click="changeLanguage(name)"
        >
            {{ language }}
        </b-button>
    </BaseCard>
</template>

<script>
import BaseCard from '@/components/BaseCard.vue'

import { mapState, mapMutations } from "vuex";

export default {
    name: "Languages",
    components: {
        BaseCard,
    },
    computed: {
        ...mapState("main", {
            languages: 'languages',
        }),
    },
    methods: {
        ...mapMutations("main", {
            setLanguage: "setLanguage",
        }),
        changeLanguage(lang) {
            this.setLanguage(lang);
        },
    }
}
</script>

<style lang="scss" scoped>

.languages {
    display: flex;
    flex-wrap: wrap;
    gap: 5%;

    .language {
        flex: 0 0 30%;
        margin-bottom: 1rem;
    }

    @media (max-width: 500px) {
        gap: 4%;
        .language {
            flex: 0 0 48%
        }
    }
}

</style>