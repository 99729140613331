<template>
    <div class="header font-weight-bold" :style="style">
        <b-container class="header__top">
            <div id="toggle-menu" class="header__top__left d-flex flex-wrap">
                <div class="brands">
                    <link-button
                        class="home"
                        variant="secondary"
                        :to="{name: 'dashboard'}"
                        @click="clearSelectedMenu"
                    >
                        {{ sysLabelGetter('home') }}
                    </link-button>
                    <b-button
                        class="brand"
                        :variant="`btn ${brandBtnVariant(id)}`"
                        v-for="(brand, id, idx) in brands"
                        :key="idx"
                        @click="updateSelectedBrand(id), updateMenuState(true)"
                    >
                        {{ labelGetter(brand.label) }}
                    </b-button>
                </div>
                <template v-if="$route.name !== 'dashboard'">
                    <!-- <div :class="['menu-button', { active: menuActive }]">
                        <div class="menu-button__title" @click="updateMenuState(!menuActive)">
                            {{ sysLabelGetter('product_selection') }}
                        </div>

                        <font-awesome-icon icon="chevron-right" @click="updateMenuState(!menuActive)" />
                    </div> -->

                    <Menu v-if="menuActive" />
                </template>
            </div>

            <div class="header__top__right">
                <button
                    id="show-search-bar-btn"
                    :class="['show-search btn btn-secondary', {active: showSearchBar}]"
                    @click="showSearchBar = !showSearchBar"
                >
                    <span>{{ sysLabelGetter('search') }}</span>
                    <font-awesome-icon icon="chevron-down"/>
                </button>

                <div class="language">
                    <Dropdown
                        class="full-name"
                        :dropdown-list="dropdownList"
                        :action="changeLanguage"
                        :selected="current_language"
                    />
                    <!-- <Dropdown
                        class="short-name"
                        :dropdown-list="dropdownListShort"
                        :action="changeLanguage"
                        :selected="current_language"
                    /> -->
                </div>

                <div class="print-list-btn">
                    <b-button @click="printAction" :disabled="!printList.length" :class="printButtonVariant">
                        <div>{{ sysLabelGetter('print_list') }}: </div>
                        <div>{{ printList.length }}</div>
                    </b-button>
                </div>
            </div>
        </b-container>
        <hr />
        <b-container :class="['header__bottom search-bar pt-3 pb-3', { active: showSearchBar }]" id="search-bar">
            <SearchList
                :label="sysLabelGetter('spare_part_order_number')"
                :search-data="search.article_no"
                type="article_no"
                @close-search-bar="showSearchBar = false"
            />
            <SearchList
                :label="sysLabelGetter('spare_part_name')"
                :search-data="search.parts"
                type="parts"
                @close-search-bar="showSearchBar = false"
            />
            <SearchList
                :label="sysLabelGetter('roof_number')"
                :search-data="search.roof_ids"
                type="roof_ids"
                @close-search-bar="showSearchBar = false"
            />
        </b-container>
    </div>
</template>

<script>
import SearchList from "@/components/SearchList.vue";
import Menu from "@/components/Menu.vue";
import Dropdown from "@/components/Dropdown.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight, faChevronLeft, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight, faChevronLeft, faChevronDown, faChevronUp);

import { mapState, mapMutations, mapGetters } from "vuex";

export default {
    components: {
        SearchList,
        Menu,
        Dropdown,
    },
    data() {
        return {
            downloads: 0,
            showSearchBar: false,
        };
    },
    computed: {
        ...mapState("main", {
            current_language: "current_language",
            selectedBrandLabel: "selectedBrandLabel",
            selectedBrand: "selectedBrand",
            brands: "brands",
            printList: "printList",
            menuActive: "menuActive",
            search: "search",
            products: "products",
            languages: 'languages',
            system_labels: 'system_labels',
        }),
        ...mapGetters("main", {
            labelGetter:'labelGetter',
            sysLabelGetter:'sysLabelGetter',
            brandVariant: "brandVariant",
        }),
        dropdownList() {
            return this.languages
        },
        // dropdownListShort() {
        //     let output = {}
        //     for( const key in this.languages ) {
        //         output[key] = key
        //     }
        //     return output
        // },
        style() {
            return this.$getBrandColor(this.selectedBrandLabel);
        },
        printButtonVariant() {
            let output = "btn-secondary";
            if (this.printList.length) return this.brandVariant;
            return output;
        },
    },
    methods: {
        ...mapMutations("main", {
            setLanguage: "setLanguage",
            updatePrintList: "updatePrintList",
            updateSelectedBrand: "updateSelectedBrand",
            updateActiveBrand: "updateActiveBrand",
            updateMenuState: "updateMenuState",
        }),
        clearSelectedMenu() {
            this.$eventBus.$emit('clear-selected-menu')
        },
        setBrand() {
            if (this.$route.params.id && this.products[this.$route.params.id] && this.products[this.$route.params.id].brand) {
                this.updateSelectedBrand(this.products[this.$route.params.id].brand);
                this.updateActiveBrand(this.products[this.$route.params.id].brand);
            } else this.updateSelectedBrand(Object.keys(this.brands)[0]);
        },
        brandBtnVariant(id) {
            if (id === this.selectedBrand) return this.brandVariant;
            return "btn-secondary";
        },
        printAction() {
            if (this.$route.name !== "print-list") {
                this.$router.push({ name: "print-list" });
            }
        },
        eventListener(e) {
            let searchBar = document.getElementById('search-bar')
            let showSearchBarBtn = document.getElementById('show-search-bar-btn')
            if (searchBar && showSearchBarBtn && !searchBar.contains(e.target) && !showSearchBarBtn.contains(e.target)) {
                this.showSearchBar = false
            }
            let element = document.querySelector(".header .header__top #toggle-menu");
            if (
                (element && !element.contains(e.target) && (e.target.classList && !e.target.classList.contains("modal"))) ||
                (e.target.classList && e.target.classList.contains("menu-products-item"))
            ) {
                this.updateMenuState(false);
                if ( this.$route.params.id ) {
                    this.updateSelectedBrand(this.products[this.$route.params.id].brand);
                }
            }
        },
        changeLanguage(lang) {
            this.setLanguage(lang);
        },
    },
    mounted() {
        this.setBrand();
        document.addEventListener("click", this.eventListener);

        let language = this.$localStorage.getItem("language");
        if (language) this.setLanguage(language);

        let printListStorage = this.$localStorage.getItem("print-list");
        if (printListStorage) this.updatePrintList(printListStorage);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.eventListener);
    },
    watch: {
        "$route.params.id"() {
            this.updateMenuState(false);
            if(this.$route.params.id && this.products[this.$route.params.id] && this.products[this.$route.params.id].brand){
                this.updateSelectedBrand(this.products[this.$route.params.id].brand);
                this.updateActiveBrand(this.products[this.$route.params.id].brand);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.header {
    background-color: #efefeb;

    button {
        white-space: nowrap;
    }
    
    &__top {
        position: relative;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px 15px 0 15px;

        @media (min-width: 992px) {
            justify-content: space-between;
        }

        &__left {
            .brands {
                display: flex;
                align-items: center;
                gap: 1rem;

                .brand {
                    @media(max-width: 991px) {
                        &:last-child {
                            margin: 0!important;
                        }
                    }
                }
            }
            @media (max-width: 450px) {
                .home,
                .brand {
                    margin-right: 10px !important;
                }
                button {
                    padding: 0.5rem 1.5rem;
                }
            }
            @media (max-width: 350px) {
                .home,
                .brand {
                    margin-right: 1px !important;
                }
            }
        }

        .menu-button {
            cursor: pointer;
            display: flex;
            align-items: center;

            &.active {
                color: var(--brand-color);
            }

            svg {
                margin-left: 5px;
                font-size: 0.6rem;
            }
        }

        &__right {
            display: flex;
            align-items: center;
            gap: 1rem;

            .show-search {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                svg {
                    transition: all .3s ease;
                    transform: rotate(0deg);
                }

                &.active {
                    svg {
                        transform: rotate(180deg);
                    }
                }

                @media(min-width: 992px) {
                    display: none;
                }
            }

            .language {
                ::v-deep .formulate-input {
                    .formulate-input-element {
                        margin-bottom: 0;
                    }
                    select {
                        padding: 0.625rem 2rem 0.625rem 0.625rem;
                    }
                }
                .short-name {
                    display: none;
                }
            }

            .show-search,
            .language,
            button {
                margin-bottom: 15px;
            }

            @media (max-width: 767px) {
                button {
                    padding-right: 1rem;
                    padding-left: 1rem;
                }
            }
            @media (max-width: 500px) {
                flex-wrap: wrap;
                justify-content: center;

                .print-list-btn {
                    flex: 1 1 100%;
                    display: flex;
                    justify-content: center;
                }

                // .language {
                //     .full-name {
                //         display: none;
                //     }
                //     .short-name {
                //         display: block;
                //     }
                // }
            }
            @media (max-width: 450px) {
                .show-search,
                .language {
                    margin-right: 10px !important;
                }
            }
            @media (max-width: 350px) {
                // .show-search,
                // .language {
                //     margin-right: 1px !important;
                // }
                button {
                    padding: 0.5rem 0.6rem;
                }
            }
        }

                .print-list-btn {

                    button {
                        display: flex;
                        align-items: center;
                        gap: 5px
                    }
                }

        #toggle-menu {
            > div {
                margin-bottom: 15px;
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;

        &:not(.active) {
            @media(max-width: 991px) {
                display: none;
            }
        }
        @media (max-width: 991px) {
            flex-direction: column;

            ::v-deep .formulate-input {
                flex: 1 1 auto;

                .formulate-input-element {
                    max-width: unset;
                }
            }
        }

        @media (max-width: 575px) {
            ::v-deep .search-list button {
                padding-right: 1rem;
                padding-left: 1rem;
            }

            ::v-deep .formulate-input {
                flex: 1 1 auto;
            }
        }
    }

    .navbar {
        a {
            color: #343a40;
        }
    }

    hr {
        margin: 0;
        border-top: 3px solid #ffffff;
    }
}
</style>