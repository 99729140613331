<template>
	<b-modal :id="`image-popup__${id}`" :ref="`image-popup__${id}`" hide-footer title="" size="xl">
		<img :src="$image(image, {extension})" alt="">
	</b-modal>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: 'image-popup'
		},
		image: {
			type: String,
			default: 'no-image'
		},
		extension: {
			type: String,
			default: ''
		},
	},
	methods: {
		hideModal() {
			this.$refs[`image-popup__${this.id}`].hide()
		},
	}
}
</script>

<style lang="scss" scoped>
img {
	width: 100%;
}
</style>