<template>
	<div class="dashboard">

		<div class="dashboard__header no-print">
			<Header/>
		</div>

		<b-container class="dashboard__body">
			<Menu v-if="$route.name === 'dashboard' && localStorageLang" :is-popup="false"/>
			<router-view v-else name="dashboard"></router-view>

			<Languages v-if="$route.name === 'dashboard' && !localStorageLang" class="mt-4"/>
		</b-container>

	</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Menu from '@/components/Menu.vue'
import Languages from '@/components/Languages.vue'

import { mapState } from "vuex";

export default {
	name: "Dashboard",
	components: {
		Header,
		Menu,
		Languages,
	},
	computed: {
		...mapState("main", {
			localStorageLang: 'localStorageLang',
		}),
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.dashboard {
	height: inherit;
	padding-top: 172px;

	@media ( max-width: 991px ) {
		padding-top: 124px;
	}

	@media ( max-width: 575px ) {
		padding-top: 114px;
	}

	@media ( max-width: 500px ) {
		padding-top: 165px;
	}

	@media ( max-width: 400px ) {
		padding-top: 150px;
	}

	&__header {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		height: 172px;
		width: 100%;
	}

	&__body {
		min-height: 100%;
		padding-top: 25px;
		padding-bottom: 25px;
	}
}

	@media print {
		.no-print {
			display: none;
		}
		.dashboard {
			padding-top: 0;
		}
	}

</style>