<template>
    <div :class="['menu', { absolute: isPopup }]" :style="style">
        <b-container>
            <BaseCard class="card">
                <b-row class="headers">
                    <!-- <b-col v-show="(smScreen && !selectedBrandId) || !smScreen" class="px-0">
              <h2>{{ sysLabelGetter('brands')}}</h2>
            </b-col> -->
                    <b-col v-show="!smScreen || (smScreen && selectedBrandId && !selectedTypeId)">
                        <h2>{{ sysLabelGetter('types') }}</h2>
                    </b-col>
                    <b-col v-show="!smScreen || (smScreen && selectedTypeId)">
                        <h2>{{ sysLabelGetter('products') }}</h2>
                    </b-col>
                    <b-col class="go-back" v-show="smScreen && goToKindLabel" @click="goToKind()">
                        <font-awesome-icon icon="chevron-left" class="mr-2" />
                        <div>{{ goToKindLabel }}</div>
                    </b-col>
                </b-row>
                <b-row class="card__body">
                    <!-- <b-col v-show="(smScreen && !selectedBrandId) || !smScreen" class="brands-col column">
              <MenuList :list="brands" list-type="brands" @item-selected="typeSelected" :is-popup="isPopup" :sm-screen="smScreen"/>
            </b-col> -->
                    <b-col v-show="!smScreen || (smScreen && selectedBrandId && !selectedTypeId)" class="types-col column">
                        <MenuList
                            list-type="types"
                            :list="typesList"
                            :is-popup="isPopup"
                            :sm-screen="smScreen"
                            :selected-id="selectedTypeId"
                            @item-selected="typeSelected"
                        />
                    </b-col>
                    <b-col v-show="!smScreen || (smScreen && selectedTypeId)" class="products-col column">
                        <MenuList
                            list-type="products"
                            :list="productsList"
                            :selected-id="$route.params.id"
                        />
                    </b-col>
                </b-row>
            </BaseCard>
        </b-container>
    </div>
</template>

<script>
import MenuList from "@/components/MenuList.vue";
import BaseCard from '@/components/BaseCard.vue'

import { mapState, mapGetters } from "vuex";

export default {
    name: "Menu",
    components: {
        MenuList,
        BaseCard,
    },
    props: {
        isPopup: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            selectedTypeId: "",
            productsList: {},
            smScreen: false,
        };
    },
    methods: {
        typeSelected(e) {
            if (e.type === "types") {
                this.selectedTypeId = e.id;
                const obj = {};
                let keysArray = [];
                if (this.structure[this.selectedBrandId][e.id]) {
                    keysArray = Object.keys(this.structure[this.selectedBrandId][e.id]);
                }
                keysArray.forEach((key) => {
                    obj[key] = this.products[key];
                });
                this.productsList = obj;
            }
        },
        goToKind() {
            if (this.selectedTypeId) {
                this.selectedTypeId = "";
                this.productsList = {};
            }
            // else if (this.selectedBrandId) {
            //     this.selectedBrandId = "";
            //     this.typesList = {};
            // }
        },
        clearSelection() {
            this.selectedTypeId = ""
            this.productsList = {}
        },
    },
    computed: {
        ...mapState("main", {
            selectedBrandLabel: "selectedBrandLabel",
            selectedBrandId: "selectedBrand",
            structure: "structure",
            brands: "brands",
            types: "types",
            products: "products",
            categories: "categories",
        }),
        ...mapGetters("main", {
            sysLabelGetter:'sysLabelGetter',
        }),
        style() {
            return this.$getBrandColor(this.selectedBrandLabel);
        },
        singleProduct() {
            let output = {}
            if(this.products[this.activeProductId]) return this.products[this.activeProductId]
            return output
        },
        typesList() {
            const obj = {};
            if (this.selectedBrandId) {
                const keysArray = Object.keys(this.structure[this.selectedBrandId]);
                keysArray.forEach((key) => {
                    obj[key] = this.types[key];
                });
            }
            if (Object.keys(obj).length) {
                if (this.activeProductId && this.selectedBrandId === this.singleProduct.brand) {
                    this.typeSelected({ id: this.singleProduct.type, type: "types" });
                }
                // else {
                //     this.typeSelected({ id: Object.keys(obj)[0], type: "types" });
                // }
            }
            return obj;
        },
        goToKindLabel() {
            let output = "";
            // if ( this.selectedBrandId ) output = this.sysLabelGetter('brand_selection');
            if (this.selectedTypeId) output = this.sysLabelGetter('type_selection');
            return output;
        },
        activeProductId() {
            let productId = "";
            if (this.$route.params.id) productId = this.$route.params.id;
            return productId;
        },
    },
    created() {
        this.$eventBus.$on( 'clear-selected-menu', this.clearSelection)
    },
    mounted() {
        if (window.innerWidth < 575) this.smScreen = true;
        else this.smScreen = false;
        window.addEventListener("resize", () => {
            if (window.innerWidth < 575) this.smScreen = true;
            else this.smScreen = false;
        });
    },
    watch: {
        selectedBrandId() {
            this.selectedTypeId = ''
            this.productsList = {}
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";

.menu {
    &.absolute {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1001;
        width: 100%;
    }

    &:not(.absolute) {
        > .container {
            padding: 0;
        }
    }

    .container {
        .card {
            &__body {
                .column {
                    max-height: 500px;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: $scrollbar_width;
                        height: $scrollbar_height;
                    }

                    &::-webkit-scrollbar-track {
                        background: $border_color;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: var(--brand-color);
                    }
                }

                .types-col {
                    max-width: 250px
                }
            }
        }
    }
}
h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.card {
    color: #333;
    border: none;
}

.headers {
    border-bottom: 1px solid $border_color;

    .go-back {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
    }
}

.brands-col {
    border-right: 1px solid $border_color;
    padding: 0;
}

.types-col {
    border-right: 1px solid $border_color;
    padding: 0;
    font-weight: bold;
}

.products-col {
    padding: 0;
    font-weight: bold;
}
</style>