<template>
    <div class="base-card">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "BaseCard",
}
</script>

<style lang="scss" scoped>

.base-card {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 3px 3px 10px rgba(39, 39, 39, 0.2);
    padding: 15px 15px 0;
}

</style>