<template>
<div class="search-list" :style="style">
    <h6 class="search-list__title">
        <b>{{ label }}</b>
    </h6>
    <div class="search-list__input">
        <FormulateInput
            type="search"
            name="sample"
            :id="`${label}-search`"
            v-model="searchTerm"
            :placeholder="sysLabelGetter('search')"
            @keyup="dataSearchFn"
            @keyup.enter="getSearchPage(), searchTerm = ''"
        />
        <b-button :class="buttonVariant" :disabled="!searchTerm || !searchQuery.length" @click="getSearchPage()">
            {{ sysLabelGetter('search') }}
        </b-button>
    </div>
    <div v-show="searchTerm && Object.keys(searchQuery).length" class="search-list__body">
        <div class="item" v-for="( item, idx ) in searchQuery" v-bind:key="idx" @click="getSearchPage(item)">
            {{item.title}}
        </div>
    </div>
</div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            searchTerm: '',
            searchTimeout: null,
            searchQuery: [],
        }
    },
    props: {
        label: {
            type: String,
            default: 'Label'
        },
        searchData: {
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapState('main', {
            activeBrandLabel: 'activeBrandLabel',
        }),
        ...mapGetters("main", {
            labelGetter:'labelGetter',
            sysLabelGetter:'sysLabelGetter',
            brandVariant: "brandVariant",
        }),
        style() {
            return this.$getBrandColor( this.activeBrandLabel );
        },
        buttonVariant() {
            let output = 'btn-secondary';
            if ( this.searchTerm && this.searchQuery.length ) return this.brandVariant;
            return output;
        },
    },
    methods: {
        ...mapMutations('main', {
            updateSearchIds: 'updateSearchIds'
        }),
        dataSearchFn(e) {
            if (e.keyCode && e.keyCode !== 13) {
                clearTimeout(this.searchTimeout);

                this.searchTimeout = setTimeout(() => {
                    this.findBySearchQuery();
                }, 200);
            } else {
                clearTimeout(this.searchTimeout);

                this.findBySearchQuery();
            }
        },
        findBySearchQuery() {
            // console.time('Execution time');
            let output = [];
            let searchTerm = this.searchTerm.replace(/[\s-[\]{}()*+!<=:?.\\^$|#\s,]/g, '');
            if ( this.searchTerm ) {
                Object.entries(this.searchData).forEach(item => {
                    let title = item[0]
                    if ( this.type === 'parts' ) title = this.labelGetter(item[0], `part ${item[1]}`)
                    // let element = item[0].toLowerCase().split(' ').join('');
                    // let searchTerm = this.searchTerm.toLowerCase().split(' ').join('');
                    let element = title.replace(/[\s-[\]{}()*+!<=:?.\\^$|#\s,]/g, '');
                    let regex = new RegExp(searchTerm, "i");
                    if ( element.match(regex) ) {
                        let obj = {
                            title,
                            id: item[1],
                        };
                        output.push(obj);
                    }
                });
            }
            this.searchQuery = output;
            // console.timeEnd('Execution time');
        },
        getSearchPage(to) {
            if (to) {
                this.searchTerm = to.title;
                this.searchQuery = [to];
            }
            if ( this.searchTerm && this.searchQuery.length ) {
                this.$emit('close-search-bar')
                let ids = [];
                this.searchQuery.forEach(item => ids.push(item.id));
                this.updateSearchIds(ids);
                if (this.$route.name !== 'search-result') {
                    this.$router.push({
                        name: 'search-result',
                        query: {
                            type: this.type,
                            term: this.searchTerm
                        }
                    });
                } else if (this.$route.query.term !== this.searchTerm || this.$route.query.type !== this.type) {
                    this.$router.push({
                        query: {
                            type: this.type,
                            term: this.searchTerm
                        }
                    });
                }
            }
        },
        eventListener(e) {
            let element = document.getElementById(`${this.label}-search`);
            if (element && !element.contains(e.target)) {
                this.searchTerm = '';
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.eventListener);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.eventListener);
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.search-list {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // &__title {}

    &__input {
        display: flex;
        align-items: flex-end;
        gap: 1rem;

        ::v-deep .formulate-input {
            margin-bottom: 0;

            .formulate-input-element {
                margin-bottom: 0;
            }
        }
        button {
            white-space: nowrap;
        }
    }

    &__body {
        position: absolute;
        top: 110%;
        background-color: #fff;
        border: 1px solid $border_color;
        border-radius: 5px;
        padding: 15px;
        max-height: 500px;
        overflow: auto;
        z-index: 5;

        &::-webkit-scrollbar {
            width: $scrollbar_width;
        }

        &::-webkit-scrollbar-track {
            background: $border_color;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--brand-color);
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .item {
            padding: 8px 0;
            cursor: pointer;
            &:not(:last-child) {
                border-bottom: 1px solid $border_color;
            }
            &:hover{
                color: #555;
            }
        }
    }
}
</style>
