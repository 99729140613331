<template>
    <b-container :style="style">
        <b-list-group>
            <b-list-group-item
                v-for="(item, id, index) in list"
                :key="index"
                :class="[`list-group-${listType}`, { active: id === activeItem }]"
            >
                <div class="label-container">
                    <div :class="['menu-item', `menu-${listType}-item`]" @click="selectItem(id)">

                        <div class="label">
                            {{ labelGetter(item.label) }}
                            <font-awesome-icon :class="['label__icon', { rtl: rtlDirection }]" icon="custom-circle-chevron"/>
                        </div>

                        <div class="profiles" v-if="listType === 'products'">
                            <div class="image" v-for="( image, idx ) in item.profiles" :key="idx">
                                <LabelImage
                                    v-if="$image(image) && !$image(image).includes('data:image/')"
                                    class="label-image"
                                    :data="{image, id: `image__${id}__${idx}`}"
                                    label-position="before"
                                    img-width="150px"
                                    :contained="true"
                                    :with-popup="false"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </b-list-group-item>
        </b-list-group>
    </b-container>
</template>

<script>
import LabelImage from '@/components/LabelImage.vue';

import { library } from "@fortawesome/fontawesome-svg-core";
import { customCircleChevron } from "@/assets/icons";
library.add(customCircleChevron);

import { mapState, mapGetters } from "vuex";

export default {
    components: {
        LabelImage,
    },
    props: {
        list: {
            type: Object,
            default: () => {},
        },
        listType: {
            type: String,
            default: "brands",
        },
        isPopup: {
            type: Boolean,
            default: true,
        },
        smScreen: {
            type: Boolean,
            default: false,
        },
        selectedId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            activeItem: "",
        };
    },
    computed: {
        ...mapState("main", {
            selectedBrandLabel: "selectedBrandLabel",
        }),
        ...mapGetters("main", {
            labelGetter:'labelGetter',
            rtlDirection: 'rtlDirection',
        }),
        style() {
            return this.$getBrandColor(this.selectedBrandLabel);
        },
    },
    watch: {
        // list(newValue, oldValue) {
        //     this.activeItem = this.selectedId;

        //     if (
        //         !this.isPopup &&
        //         !this.smScreen &&
        //         this.listType === "types" &&
        //         !Object.keys(oldValue).length &&
        //         Object.keys(newValue).length
        //     ) {
        //         this.selectItem(Object.keys(this.list)[0]);
        //     }
        // },
        selectedId() {
            this.activeItem = this.selectedId;
        },
    },
    mounted() {
        this.activeItem = this.selectedId;
        if (!this.isPopup && !this.smScreen && this.listType !== "products" && Object.keys(this.list).length) {
            this.selectItem(Object.keys(this.list)[0]);
        }
    },
    methods: {
        selectItem(id) {
            this.$emit("item-selected", { id, type: this.listType });
            this.activeItem = id;
            if (this.listType === "products" && this.$route.params.id !== id) {
                this.$router.push({ name: "product", params: { id } });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import '@/styles/mixins';

.container {
    padding: 0;
}
.list-group {
    border-radius: unset;
    .list-group-item {
        line-height: 2.2rem;
        background-color: #fff;
        font-size: 14px;
        border: none;
        cursor: pointer;
        padding: 0;
        margin: 0;

        &.list-group-products {
            &:not(:last-child) {
                border-bottom: 1px solid $border_color;
            }
        }

        ul {
            list-style-type: none;
        }
        .label-container {
            padding: 10px 0;

            @media( min-width: 992px ) {
                @include transition--ease-out;

                &:hover {
                    opacity: 0.7;
                }
            }

            .menu-item {
                padding: 0 20px;

                .label {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    &__icon {
                        font-size: 1.125rem;

                        &.rtl {
                            transform: rotate(180deg);
                        }
                    }
                }

                .profiles {
                    display: flex;
                    width: 100%;
                    overflow-x: auto;
                    .image {
                        margin-right: 5px;
                        .label-image {
                            margin-bottom: 10px;
                        }
                    }

                    &::-webkit-scrollbar {
                        width: $scrollbar_width;
                        height: $scrollbar_height;
                    }

                    &::-webkit-scrollbar-track {
                        background: $border_color;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: var(--brand-color);
                    }
                }
            }
        }
        &.active {
            .label-container {
                color: var(--brand-color);
                background-color: #efefeb;
            }
        }
    }
}
</style>